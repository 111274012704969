// This file is automatically generated by `generated_js` task. Any changes will be lost
/* tslint:disable */
export const GoCDVersion = {
  "version": "24.5.0",
  "buildNumber": "19738",
  "gitSha": "b49ef17288fd0c5638f23280b2efce50b1825c14",
  "fullVersion": "24.5.0-19738",
  "formattedVersion": "24.5.0 (19738-b49ef17288fd0c5638f23280b2efce50b1825c14)",
  "copyrightYear": "2024"
}


function stripLeadingPrefix(suffix: string, prefix: string) {
  if (suffix.startsWith(prefix)) {
      suffix = suffix.substring(1);
  }
  return suffix;
}

export function docsUrl(suffix: string = '') {
  return `https://docs.gocd.org/24.5.0/${stripLeadingPrefix(suffix, '/')}`
}

export function apiDocsUrl(suffix: string = '') {
  return `https://api.gocd.org/24.5.0/${stripLeadingPrefix(suffix, '#')}`
}
    
